<template>
  <div>
  <!--   <Head v-if="getKeys !== null">
      <Title>{{$rt(getKeys.title) }}</Title>
      <Meta name="description" :content="$rt(getKeys.description)" />
    </Head> -->
  </div>
  <BaseBanner :items="getKeys" />
  <BaseViewAll :keys="getKeys" />
</template>

<script>
import { usePillarStore } from '~/store/PillarStore';
import BaseViewAll from '~/components/base/BaseViewAll.vue';
import BaseBanner from '~/components/base/BaseBanner.vue';

export default {
  components: {
    BaseBanner,
    BaseViewAll,
  },
  setup() {
    definePageMeta({
      layout: 'solutions',
    });

    const pillarStore = usePillarStore();
    return {
      pillarStore,
    };
  },
  data() {
    return {
      isArrow: false,
      solutionsData: { description: ' ' },
    };
  },
  async created() {
   
  },
  computed: {
    getKeys() {
      const route = useRoute();
      const path = route.params._subId;
      const s = this.$tm(path);
      var solData = {
        'category': s.category,
        'description': s.description === null ? '' : s.description,
        'resourceUri': s.resourceUri,
        'title': s.title,
        'pages': []
      };
      s.pages.forEach((pg) => {
        var page = {
          'imageUri': this.$rt(pg.imageUri),
          'path': this.$rt(pg.path),
          'title': this.$rt(pg.title)
        };
        solData.pages.push(page);
      })
      return solData;
    },
    getItems() {
      return this.items;
    },
  },
  methods: {
    toUpper(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join('');
    },
    
    handleHover(item) {
      item.isArrow = true;
    },
    handleLeave(item) {
      item.isArrow = false;
    },
  },
};
</script>
<style scoped>


@media only screen and (max-width: 950px) {

}

@media only screen and (max-width: 700px) {

}
</style>